import React from 'react'

import { Helmet } from 'react-helmet'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const Layout: React.FC = ({ children }) => {
	const domain = typeof window !== 'undefined' ? window.location.href : ''

	return (
		<>
			<Helmet>
				<script src='https://cdn.jsdelivr.net/npm/boxicons@2.0.8/dist/boxicons.min.js'></script>

				<title>ADCC LMS</title>
				<meta name='title' content='ADCC LMS' />
				<meta name='description' content='ADCC LMS - Bleeding-edge LMS Solution for all schools and colleges.' />

				<meta property='og:type' content='website' />
				<meta property='og:url' content={`${domain}`} />
				<meta property='og:title' content='ADCC LMS' />
				<meta property='og:description' content='ADCC LMS - Bleeding-edge LMS Solution for all schools and colleges.' />
				<meta property='og:image' content={`${domain}1.png`} />

				<meta property='twitter:card' content='summary_large_image' />
				<meta property='twitter:url' content={`${domain}`} />
				<meta property='twitter:title' content='ADCC LMS' />
				<meta property='twitter:description' content='ADCC LMS - Bleeding-edge LMS Solution for all schools and colleges.' />
				<meta property='twitter:image' content={`${domain}1.png`} />
			</Helmet>
			<ToastContainer></ToastContainer>
			<>{children}</>
		</>
	)
}
