import './collapsible.scss'
import React, { ReactElement } from 'react'

export const Collapsible: React.FC<{
	collapsed?: boolean
	setCollapsed: React.Dispatch<React.SetStateAction<boolean>>
	title?: ReactElement<any, any>
	data?: ReactElement<any, any>
}> = ({ collapsed, title, data, setCollapsed }) => {
	return (
		<>
			<div className='collapsible'>
				<div onClick={() => setCollapsed((state: boolean) => !state)} className='collapsed non-collapsed'>
					{title}
				</div>
				<div className={collapsed ? `collapsed non-collapsed` : `collapsed`}>{data}</div>
			</div>
		</>
	)
}
