import React from 'react'
import { Link } from 'gatsby'

const PageLinks: React.FC = () => {
	return (
		<div className='row serviceRow mx-1'>
			<div className='col-md-4 my-2'>
				<Link to='/calendar'>
					<div className='bg-warning rounded-circle serviceBox'>
						<i className='bx bxs-calendar bx-md text-white' style={{ padding: '0.6rem' }}></i>
					</div>
					<h6 className='mt-2 text-white'>Calendar</h6>
				</Link>
			</div>

			{/* <div className="col-md-4 my-2">
				<Link to="/chat">
					<div className="bg-warning rounded-circle serviceBox">
						<i
							className="bx bxs-chat bx-md text-white"
							style={{ padding: "0.6rem" }}
						></i>
					</div>
					<h6 className="mt-2 text-white">Chat</h6>
				</Link>
			</div> */}

			<div className='col-md-4 my-2'>
				<Link to='/privateFiles'>
					<div className='bg-info rounded-circle serviceBox'>
						<i className='bx bxs-file bx-md text-white' style={{ padding: '0.6rem' }}></i>
					</div>
					<h6 className='mt-2 ml-2 text-white'>Files</h6>
				</Link>
			</div>

			<div className='col-md-4 my-2'>
				<Link to='/todo'>
					<div className='bg-success rounded-circle serviceBox'>
						<i className='bx bx-task bx-md text-white' style={{ padding: '0.6rem' }}></i>
					</div>
					<h6 className='mt-2 text-white'>Activities</h6>
				</Link>
			</div>

			<div className='col-md-4 my-2'>
				<Link to='/classes'>
					<div className='bg-primary rounded-circle serviceBox'>
						<i className='bx bxs-chalkboard bx-md text-white' style={{ padding: '0.6rem' }}></i>
					</div>
					<h6 className='mt-2 text-white'>Classes</h6>
				</Link>
			</div>

			<div className='col-md-4 my-2'>
				<Link to='/profile/view'>
					<div className='bg-danger rounded-circle serviceBox'>
						<i className='bx bxs-user bx-md text-white' style={{ padding: '0.6rem' }}></i>
					</div>
					<h6 className='mt-2 text-white'>Profile</h6>
				</Link>
			</div>
		</div>
	)
}
export default PageLinks
