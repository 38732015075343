import React, { Fragment, memo, useEffect } from 'react'
import { Link } from 'gatsby'

import './nav.scss'
import { Icon } from '@iconify/react'

import { Collapsible } from 'components/common/Collapsible'
import { useLocation } from '@reach/router'
import { useAppDispatch } from 'redux/hooks'
import { ApplicationStateActions } from 'redux/slices/ApplicationState'
import { logout } from '../../API/auth'
interface SideNavProps {
	isNavExpanded: boolean
	showNavbar(): void
	hideNavbar(): void
	isNavLocked: boolean
	toggleNavbar(): void
}

const SideNav: React.FC<SideNavProps> = ({ isNavExpanded, showNavbar, hideNavbar, isNavLocked, toggleNavbar }) => {
	const [collapsed, setCollapsed] = React.useState(false)
	const { search } = useLocation()
	const dispatch = useAppDispatch()
	useEffect(() => {
		dispatch(ApplicationStateActions.setState({ search: search ?? '' }))
	}, [search])
	return (
		<div className={isNavExpanded ? 'l-navbar show' : 'l-navbar'} id='nav-bar' onMouseEnter={showNavbar} onMouseLeave={hideNavbar}>
			<nav className='nav'>
				<div>
					<div className='mb-4 nav__logo'>
						<a className='d-inline'>
							<Icon icon='bx:bxs-school' className='mr-3 nav__logo-icon' />

							<span className='nav__logo-name'>LMS System</span>
						</a>
						<header className='d-md-block d-none float-right pr-3' id='header'>
							<div className='header__toggle' id='header-toggle'>
								{isNavExpanded && isNavLocked ? (
									<Icon icon={'bx:bx-x'} id='header-toggle' onTouchStart={toggleNavbar} onClick={toggleNavbar} />
								) : (
									<Icon icon={'bx:bx-menu'} id='header-toggle' onClick={toggleNavbar} />
								)}
							</div>
						</header>
					</div>

					<div className='nav__list'>
						<Link to='/dashboard' activeClassName='sideActive' className='nav__link'>
							<Icon icon='bx:bxs-grid-alt' className='nav__icon' />
							<span className='nav__name'>Dashboard</span>
						</Link>
						<Collapsible
							title={
								<Fragment>
									<Link to={`/user/courses`} className={`nav__link`} activeClassName={search === '' ? 'sideActive' : ''}>
										<Icon icon='bx:bxs-chalkboard' className='nav__icon' />
										<span className='nav__name'>Courses</span>
									</Link>
								</Fragment>
							}
							data={
								<Fragment>
									{['academics', 'mentorship', 'research', 'co-curricular'].map((elem, index) => {
										return (
											<Link
												key={elem + index}
												to={`/user/courses?type=${elem}`}
												className={search === `?type=${elem}` ? 'nav__link sideActive' : 'nav__link'}
											>
												<Icon icon='bx:bxs-checkbox-minus' className='nav__icon' />

												<span className={'capital nav__name'}>{elem}</span>
											</Link>
										)
									})}
								</Fragment>
							}
							collapsed={collapsed}
							setCollapsed={setCollapsed}
						></Collapsible>

						<Link to='/tracking' activeClassName='sideActive' className='nav__link'>
							<Icon icon='bx:bx-task' className='nav__icon' />
							<span className='nav__name'>Upcoming Activities</span>
						</Link>

						<Link to='/calendar' activeClassName='sideActive' className='nav__link'>
							<Icon icon='bx:bxs-message-square-detail' className='nav__icon' />
							<span className='nav__name'>Calendar</span>
						</Link>

						<Link to='/files' activeClassName='sideActive' className='nav__link'>
							<Icon icon='bx:bxs-folder' className='nav__icon' />
							<span className='nav__name'>Private Files</span>
						</Link>
						<Link to='/live-lectures' activeClassName='sideActive' className='nav__link'>
							<Icon icon='bx:bxs-videos' className='nav__icon' />
							<span className='nav__name'>Live Lectures</span>
						</Link>

						{/* <Link to='/chat' activeClassName='sideActive' className='nav__link'>
							<i className='bx bxs-chat nav__icon'></i>
							<span className='nav__name'>Chat</span>
						</Link> */}

						<Link to='/profile/view' activeClassName='sideActive' className='nav__link'>
							<Icon icon='bx:bxs-cog' className='nav__icon' />
							<span className='nav__name'>Settings</span>
						</Link>
						<a
							onClick={async (e: any) => {
								e.preventDefault()
								const log = await logout()
								if (log) {
									localStorage.clear()
									window?.location?.reload()
								}
							}}
							href='/'
							className='nav__link'
						>
							<Icon icon='bx:bx-log-out' className='nav__icon' />

							<span className='nav__name'>Log Out</span>
						</a>
					</div>
				</div>
			</nav>
		</div>
	)
}

export default memo(SideNav)
