import React, { useState } from 'react'
import TopNav from 'components/dashboard/TopNav'
import SideNav from 'components/dashboard/SideNav'
import { useAppSelector } from 'redux/hooks'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Layout } from './BaseLayout'

export const ClassLayout: React.FC = ({ children }) => {
	const [isNavExpanded, setNavExpanded] = useState(false)
	const [isNavLocked, setNavLocked] = useState(false)

	const isTeacher = useAppSelector((state) => state.CourseDisplayReducer.viewAs) === 'Teacher'

	const showNavbar = () => {
		if (!isNavLocked) setNavExpanded(true)
	}
	const hideNavbar = () => {
		if (!isNavLocked) setNavExpanded(false)
	}
	const toggleNavbar = () => {
		setNavExpanded(isNavExpanded)
		setNavLocked(!isNavLocked)
	}
	const toggleMobileNavbar = () => {
		setNavExpanded(!isNavExpanded)
		setNavLocked(!isNavLocked)
	}

	const course_id = useAppSelector((state) => state.CourseDisplayReducer.course_id)

	return (
		<>
			<Layout />
			<ToastContainer></ToastContainer>
			<div className='mainContent'>
				<div className={isNavExpanded ? 'body-pd' : 'body'} id='body-pd'>
					{course_id ? (
						<>
							<TopNav
								menuArray={[
									{
										id: 1,
										title: 'Activities',
										url: `/course/${course_id}/activities`,
									},

									...(isTeacher
										? [
												{
													id: 2,
													title: 'Classwork',
													url: `/course/${course_id}/classwork`,
												},
												{
													id: 3,
													title: 'People',
													url: `/course/${course_id}/people`,
												},
										  ]
										: []),
									{
										id: 4,
										title: 'Grades',
										url: `/course/${course_id}/grades`,
									},
									{
										id: 5,
										title: 'Reporting',
										url: `/course/${course_id}/reporting`,
									},
									// ...(isTeacher ? [] : []),
								]}
								toggleMobileNavbar={toggleMobileNavbar}
								isNavExpanded={isNavExpanded}
							></TopNav>

							<SideNav
								isNavExpanded={isNavExpanded}
								showNavbar={showNavbar}
								hideNavbar={hideNavbar}
								isNavLocked={isNavLocked}
								toggleNavbar={toggleNavbar}
							></SideNav>
							<>{children}</>
						</>
					) : (
						<>{children}</>
					)}
				</div>
			</div>
		</>
	)
}

export default ClassLayout
