import React, { memo } from 'react'
import { Link } from 'gatsby'

import './nav.scss'
import { Icon } from '@iconify/react'
import { logout } from '../../API/auth'
import { getUserData } from 'API/user'
import { navigate } from 'gatsby'
import { useGetUsersData } from 'hooks/useGetUsersData'
import { PromiseType } from 'utility-types'

interface SideNavProps {
	isNavExpanded: boolean
	showNavbar(): void
	hideNavbar(): void
	isNavLocked: boolean
	toggleNavbar(): void
}

const SideNav: React.FC<SideNavProps> = ({ isNavExpanded, showNavbar, hideNavbar, isNavLocked, toggleNavbar }) => {
	const [userData, setUserData] = React.useState<PromiseType<ReturnType<typeof getUserData>>>()

	React.useEffect(() => {
		const run = async () => {
			const userData = await getUserData()
			if (userData) {
				setUserData(userData)
			}
		}
		run()
	}, [])

	return (
		<div className={isNavExpanded ? 'l-navbar show' : 'l-navbar'} id='nav-bar' onMouseEnter={showNavbar} onMouseLeave={hideNavbar}>
			<nav className='nav'>
				<div>
					<div className='mb-4 nav__logo'>
						<a className='d-inline'>
							<Icon icon='bx:bxs-school' className=' mr-3 nav__logo-icon'></Icon>
							<span className='nav__logo-name'>LMS System</span>
						</a>
						<header className='d-md-block d-none float-right pr-3' id='header'>
							<div className='header__toggle'>
								<Icon icon={isNavExpanded && isNavLocked ? 'bx:bx-x' : 'bx:bx-menu'} id='header-toggle' onClick={toggleNavbar}></Icon>
							</div>
						</header>
					</div>

					<div className='nav__list'>
						<Link to='/admin' activeClassName='sideActive' className='nav__link'>
							<Icon icon='bx:bxs-grid-alt' className=' nav__icon'></Icon>
							<span className='nav__name'>Dashboard</span>
						</Link>

						<Link to='/admin/course' activeClassName='sideActive' className='nav__link'>
							<Icon icon='bx:bx-plus-medical' className=' nav__icon'></Icon>
							<span className='nav__name'>Courses</span>
						</Link>

						<Link to='/admin/add/users' activeClassName='sideActive' className='nav__link'>
							<Icon icon='bx:bxs-user-plus' className=' nav__icon'></Icon>
							<span className='nav__name'>Add Users</span>
						</Link>
						<Link to='/admin/reporting' activeClassName='sideActive' className='nav__link'>
							<Icon icon='carbon:result' className=' nav__icon'></Icon>
							<span className='nav__name'>Course Reporting</span>
						</Link>
						<Link to='/admin/reporting_teacher' activeClassName='sideActive' className='nav__link'>
							<Icon icon='carbon:result' className=' nav__icon'></Icon>
							<span className='nav__name'>Teacher Reporting</span>
						</Link>
						{userData?.admin && (
							<Link to='/admin/group' activeClassName='sideActive' className='nav__link'>
								<Icon icon='bx:bxs-group' className=' nav__icon'></Icon>
								<span className='nav__name'>Groups</span>
							</Link>
						)}
						{userData?.admin && (
							<Link to='/admin/liveusers' activeClassName='sideActive' className='nav__link'>
								<Icon icon='bx:bxs-user' className=' nav__icon'></Icon>
								<span className='nav__name'>Active Users</span>
							</Link>
						)}

						<Link to='/admin/add/users' activeClassName='sideActive' className='nav__link'>
							<Icon icon='bx:bxs-user' className=' nav__icon'></Icon>
							<span className='nav__name'>Management</span>
						</Link>
					</div>
				</div>

				<a
					onClick={async (e: any) => {
						e.preventDefault()
						const log = await logout()
						if (log) {
							localStorage.clear()
							navigate('/')

							// window?.location?.reload()
						}
					}}
					href='/'
					className='nav__link'
				>
					<Icon icon='bx:bx-log-out' className=' nav__icon'></Icon>
					<span className='nav__name'>Log Out</span>
				</a>
			</nav>
		</div>
	)
}

export default memo(SideNav)
