// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-add-classes-jsx": () => import("./../../../src/pages/admin/add/classes.jsx" /* webpackChunkName: "component---src-pages-admin-add-classes-jsx" */),
  "component---src-pages-admin-add-courses-[id]-users-group-2-tsx": () => import("./../../../src/pages/admin/add/courses/[id]/users/group 2.tsx" /* webpackChunkName: "component---src-pages-admin-add-courses-[id]-users-group-2-tsx" */),
  "component---src-pages-admin-add-courses-[id]-users-group-3-tsx": () => import("./../../../src/pages/admin/add/courses/[id]/users/group 3.tsx" /* webpackChunkName: "component---src-pages-admin-add-courses-[id]-users-group-3-tsx" */),
  "component---src-pages-admin-add-courses-[id]-users-student-group-tsx": () => import("./../../../src/pages/admin/add/courses/[id]/users/student/group.tsx" /* webpackChunkName: "component---src-pages-admin-add-courses-[id]-users-student-group-tsx" */),
  "component---src-pages-admin-add-courses-[id]-users-student-tsx": () => import("./../../../src/pages/admin/add/courses/[id]/users/student.tsx" /* webpackChunkName: "component---src-pages-admin-add-courses-[id]-users-student-tsx" */),
  "component---src-pages-admin-add-courses-[id]-users-teacher-group-tsx": () => import("./../../../src/pages/admin/add/courses/[id]/users/teacher/group.tsx" /* webpackChunkName: "component---src-pages-admin-add-courses-[id]-users-teacher-group-tsx" */),
  "component---src-pages-admin-add-courses-[id]-users-teacher-tsx": () => import("./../../../src/pages/admin/add/courses/[id]/users/teacher.tsx" /* webpackChunkName: "component---src-pages-admin-add-courses-[id]-users-teacher-tsx" */),
  "component---src-pages-admin-add-courses-batch-tsx": () => import("./../../../src/pages/admin/add/courses/batch.tsx" /* webpackChunkName: "component---src-pages-admin-add-courses-batch-tsx" */),
  "component---src-pages-admin-add-courses-index-tsx": () => import("./../../../src/pages/admin/add/courses/index.tsx" /* webpackChunkName: "component---src-pages-admin-add-courses-index-tsx" */),
  "component---src-pages-admin-add-group-[id]-student-tsx": () => import("./../../../src/pages/admin/add/group/[id]/student.tsx" /* webpackChunkName: "component---src-pages-admin-add-group-[id]-student-tsx" */),
  "component---src-pages-admin-add-group-index-tsx": () => import("./../../../src/pages/admin/add/group/index.tsx" /* webpackChunkName: "component---src-pages-admin-add-group-index-tsx" */),
  "component---src-pages-admin-add-groups-jsx": () => import("./../../../src/pages/admin/add/groups.jsx" /* webpackChunkName: "component---src-pages-admin-add-groups-jsx" */),
  "component---src-pages-admin-add-users-batch-tsx": () => import("./../../../src/pages/admin/add/users/batch.tsx" /* webpackChunkName: "component---src-pages-admin-add-users-batch-tsx" */),
  "component---src-pages-admin-add-users-index-tsx": () => import("./../../../src/pages/admin/add/users/index.tsx" /* webpackChunkName: "component---src-pages-admin-add-users-index-tsx" */),
  "component---src-pages-admin-course-[id]-logs-user-id-tsx": () => import("./../../../src/pages/admin/course/[id]/logs/[user_id].tsx" /* webpackChunkName: "component---src-pages-admin-course-[id]-logs-user-id-tsx" */),
  "component---src-pages-admin-course-[id]-tsx": () => import("./../../../src/pages/admin/course/[id].tsx" /* webpackChunkName: "component---src-pages-admin-course-[id]-tsx" */),
  "component---src-pages-admin-course-index-tsx": () => import("./../../../src/pages/admin/course/index.tsx" /* webpackChunkName: "component---src-pages-admin-course-index-tsx" */),
  "component---src-pages-admin-group-[id]-tsx": () => import("./../../../src/pages/admin/group/[id].tsx" /* webpackChunkName: "component---src-pages-admin-group-[id]-tsx" */),
  "component---src-pages-admin-group-index-tsx": () => import("./../../../src/pages/admin/group/index.tsx" /* webpackChunkName: "component---src-pages-admin-group-index-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-admin-liveusers-index-tsx": () => import("./../../../src/pages/admin/liveusers/index.tsx" /* webpackChunkName: "component---src-pages-admin-liveusers-index-tsx" */),
  "component---src-pages-admin-management-index-tsx": () => import("./../../../src/pages/admin/management/index.tsx" /* webpackChunkName: "component---src-pages-admin-management-index-tsx" */),
  "component---src-pages-admin-reporting-index-tsx": () => import("./../../../src/pages/admin/reporting/index.tsx" /* webpackChunkName: "component---src-pages-admin-reporting-index-tsx" */),
  "component---src-pages-admin-reporting-teacher-index-tsx": () => import("./../../../src/pages/admin/reporting_teacher/index.tsx" /* webpackChunkName: "component---src-pages-admin-reporting-teacher-index-tsx" */),
  "component---src-pages-admin-user-index-tsx": () => import("./../../../src/pages/admin/user/index.tsx" /* webpackChunkName: "component---src-pages-admin-user-index-tsx" */),
  "component---src-pages-admin-user-user-id-tsx": () => import("./../../../src/pages/admin/user/[user_id].tsx" /* webpackChunkName: "component---src-pages-admin-user-user-id-tsx" */),
  "component---src-pages-calendar-tsx": () => import("./../../../src/pages/calendar.tsx" /* webpackChunkName: "component---src-pages-calendar-tsx" */),
  "component---src-pages-course-[id]-activities-activity-component-tsx": () => import("./../../../src/pages/course/[id]/activities/ActivityComponent.tsx" /* webpackChunkName: "component---src-pages-course-[id]-activities-activity-component-tsx" */),
  "component---src-pages-course-[id]-activities-assignment-assignment-id-tsx": () => import("./../../../src/pages/course/[id]/activities/assignment/[assignment_id].tsx" /* webpackChunkName: "component---src-pages-course-[id]-activities-assignment-assignment-id-tsx" */),
  "component---src-pages-course-[id]-activities-assignment-list-submissions-tsx": () => import("./../../../src/pages/course/[id]/activities/assignment/ListSubmissions.tsx" /* webpackChunkName: "component---src-pages-course-[id]-activities-assignment-list-submissions-tsx" */),
  "component---src-pages-course-[id]-activities-external-external-id-tsx": () => import("./../../../src/pages/course/[id]/activities/external/[external_id].tsx" /* webpackChunkName: "component---src-pages-course-[id]-activities-external-external-id-tsx" */),
  "component---src-pages-course-[id]-activities-external-video-external-id-tsx": () => import("./../../../src/pages/course/[id]/activities/external-video/[external_id].tsx" /* webpackChunkName: "component---src-pages-course-[id]-activities-external-video-external-id-tsx" */),
  "component---src-pages-course-[id]-activities-feedback-feedback-id-tsx": () => import("./../../../src/pages/course/[id]/activities/feedback/[feedback_id].tsx" /* webpackChunkName: "component---src-pages-course-[id]-activities-feedback-feedback-id-tsx" */),
  "component---src-pages-course-[id]-activities-forum-forum-id-tsx": () => import("./../../../src/pages/course/[id]/activities/forum/[forum_id].tsx" /* webpackChunkName: "component---src-pages-course-[id]-activities-forum-forum-id-tsx" */),
  "component---src-pages-course-[id]-activities-index-tsx": () => import("./../../../src/pages/course/[id]/activities/index.tsx" /* webpackChunkName: "component---src-pages-course-[id]-activities-index-tsx" */),
  "component---src-pages-course-[id]-activities-lecture-lecture-id-tsx": () => import("./../../../src/pages/course/[id]/activities/lecture/[lecture_id].tsx" /* webpackChunkName: "component---src-pages-course-[id]-activities-lecture-lecture-id-tsx" */),
  "component---src-pages-course-[id]-activities-module-module-id-tsx": () => import("./../../../src/pages/course/[id]/activities/module/[module_id].tsx" /* webpackChunkName: "component---src-pages-course-[id]-activities-module-module-id-tsx" */),
  "component---src-pages-course-[id]-activities-quiz-quiz-id-livelogs-tsx": () => import("./../../../src/pages/course/[id]/activities/quiz/[quiz_id]/livelogs.tsx" /* webpackChunkName: "component---src-pages-course-[id]-activities-quiz-quiz-id-livelogs-tsx" */),
  "component---src-pages-course-[id]-activities-quiz-quiz-id-tsx": () => import("./../../../src/pages/course/[id]/activities/quiz/[quiz_id].tsx" /* webpackChunkName: "component---src-pages-course-[id]-activities-quiz-quiz-id-tsx" */),
  "component---src-pages-course-[id]-activities-resource-resource-id-tsx": () => import("./../../../src/pages/course/[id]/activities/resource/[resource_id].tsx" /* webpackChunkName: "component---src-pages-course-[id]-activities-resource-resource-id-tsx" */),
  "component---src-pages-course-[id]-classwork-add-add-file-to-cdn-2-tsx": () => import("./../../../src/pages/course/[id]/classwork/add/addFileToCDN 2.tsx" /* webpackChunkName: "component---src-pages-course-[id]-classwork-add-add-file-to-cdn-2-tsx" */),
  "component---src-pages-course-[id]-classwork-add-add-file-to-cdn-tsx": () => import("./../../../src/pages/course/[id]/classwork/add/addFileToCDN.tsx" /* webpackChunkName: "component---src-pages-course-[id]-classwork-add-add-file-to-cdn-tsx" */),
  "component---src-pages-course-[id]-classwork-add-assignment-tsx": () => import("./../../../src/pages/course/[id]/classwork/add/assignment.tsx" /* webpackChunkName: "component---src-pages-course-[id]-classwork-add-assignment-tsx" */),
  "component---src-pages-course-[id]-classwork-add-cam-js": () => import("./../../../src/pages/course/[id]/classwork/add/cam.js" /* webpackChunkName: "component---src-pages-course-[id]-classwork-add-cam-js" */),
  "component---src-pages-course-[id]-classwork-add-external-tsx": () => import("./../../../src/pages/course/[id]/classwork/add/external.tsx" /* webpackChunkName: "component---src-pages-course-[id]-classwork-add-external-tsx" */),
  "component---src-pages-course-[id]-classwork-add-external-video-tsx": () => import("./../../../src/pages/course/[id]/classwork/add/external-video.tsx" /* webpackChunkName: "component---src-pages-course-[id]-classwork-add-external-video-tsx" */),
  "component---src-pages-course-[id]-classwork-add-feedback-tsx": () => import("./../../../src/pages/course/[id]/classwork/add/feedback.tsx" /* webpackChunkName: "component---src-pages-course-[id]-classwork-add-feedback-tsx" */),
  "component---src-pages-course-[id]-classwork-add-forum-tsx": () => import("./../../../src/pages/course/[id]/classwork/add/forum.tsx" /* webpackChunkName: "component---src-pages-course-[id]-classwork-add-forum-tsx" */),
  "component---src-pages-course-[id]-classwork-add-import-tsx": () => import("./../../../src/pages/course/[id]/classwork/add/import.tsx" /* webpackChunkName: "component---src-pages-course-[id]-classwork-add-import-tsx" */),
  "component---src-pages-course-[id]-classwork-add-lecture-tsx": () => import("./../../../src/pages/course/[id]/classwork/add/lecture.tsx" /* webpackChunkName: "component---src-pages-course-[id]-classwork-add-lecture-tsx" */),
  "component---src-pages-course-[id]-classwork-add-module-tsx": () => import("./../../../src/pages/course/[id]/classwork/add/module.tsx" /* webpackChunkName: "component---src-pages-course-[id]-classwork-add-module-tsx" */),
  "component---src-pages-course-[id]-classwork-add-notification-tsx": () => import("./../../../src/pages/course/[id]/classwork/add/notification.tsx" /* webpackChunkName: "component---src-pages-course-[id]-classwork-add-notification-tsx" */),
  "component---src-pages-course-[id]-classwork-add-proctor-tsx": () => import("./../../../src/pages/course/[id]/classwork/add/proctor.tsx" /* webpackChunkName: "component---src-pages-course-[id]-classwork-add-proctor-tsx" */),
  "component---src-pages-course-[id]-classwork-add-quiz-tsx": () => import("./../../../src/pages/course/[id]/classwork/add/quiz.tsx" /* webpackChunkName: "component---src-pages-course-[id]-classwork-add-quiz-tsx" */),
  "component---src-pages-course-[id]-classwork-add-resource-tsx": () => import("./../../../src/pages/course/[id]/classwork/add/resource.tsx" /* webpackChunkName: "component---src-pages-course-[id]-classwork-add-resource-tsx" */),
  "component---src-pages-course-[id]-classwork-add-screenshort-tsx": () => import("./../../../src/pages/course/[id]/classwork/add/screenshort.tsx" /* webpackChunkName: "component---src-pages-course-[id]-classwork-add-screenshort-tsx" */),
  "component---src-pages-course-[id]-classwork-index-tsx": () => import("./../../../src/pages/course/[id]/classwork/index.tsx" /* webpackChunkName: "component---src-pages-course-[id]-classwork-index-tsx" */),
  "component---src-pages-course-[id]-grades-index-tsx": () => import("./../../../src/pages/course/[id]/grades/index.tsx" /* webpackChunkName: "component---src-pages-course-[id]-grades-index-tsx" */),
  "component---src-pages-course-[id]-index-tsx": () => import("./../../../src/pages/course/[id]/index.tsx" /* webpackChunkName: "component---src-pages-course-[id]-index-tsx" */),
  "component---src-pages-course-[id]-logs-index-tsx": () => import("./../../../src/pages/course/[id]/logs/index.tsx" /* webpackChunkName: "component---src-pages-course-[id]-logs-index-tsx" */),
  "component---src-pages-course-[id]-logs-user-id-tsx": () => import("./../../../src/pages/course/[id]/logs/[user_id].tsx" /* webpackChunkName: "component---src-pages-course-[id]-logs-user-id-tsx" */),
  "component---src-pages-course-[id]-people-tsx": () => import("./../../../src/pages/course/[id]/people.tsx" /* webpackChunkName: "component---src-pages-course-[id]-people-tsx" */),
  "component---src-pages-course-[id]-reporting-asset-asset-id-tsx": () => import("./../../../src/pages/course/[id]/reporting/asset/[asset_id].tsx" /* webpackChunkName: "component---src-pages-course-[id]-reporting-asset-asset-id-tsx" */),
  "component---src-pages-course-[id]-reporting-index-tsx": () => import("./../../../src/pages/course/[id]/reporting/index.tsx" /* webpackChunkName: "component---src-pages-course-[id]-reporting-index-tsx" */),
  "component---src-pages-dashboard-index-tsx": () => import("./../../../src/pages/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-index-tsx" */),
  "component---src-pages-data-index-tsx": () => import("./../../../src/pages/data/index.tsx" /* webpackChunkName: "component---src-pages-data-index-tsx" */),
  "component---src-pages-files-index-tsx": () => import("./../../../src/pages/files/index.tsx" /* webpackChunkName: "component---src-pages-files-index-tsx" */),
  "component---src-pages-forgot-password-jsx": () => import("./../../../src/pages/forgot_password.jsx" /* webpackChunkName: "component---src-pages-forgot-password-jsx" */),
  "component---src-pages-help-jsx": () => import("./../../../src/pages/help.jsx" /* webpackChunkName: "component---src-pages-help-jsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-live-lectures-tsx": () => import("./../../../src/pages/live-lectures.tsx" /* webpackChunkName: "component---src-pages-live-lectures-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-naac-tsx": () => import("./../../../src/pages/naac.tsx" /* webpackChunkName: "component---src-pages-naac-tsx" */),
  "component---src-pages-notification-index-tsx": () => import("./../../../src/pages/notification/index.tsx" /* webpackChunkName: "component---src-pages-notification-index-tsx" */),
  "component---src-pages-profile-view-tsx": () => import("./../../../src/pages/profile/view.tsx" /* webpackChunkName: "component---src-pages-profile-view-tsx" */),
  "component---src-pages-quiz-course-[id]-quiz-id-tsx": () => import("./../../../src/pages/quiz/course/[id]/[quiz_id].tsx" /* webpackChunkName: "component---src-pages-quiz-course-[id]-quiz-id-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset_password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-tracking-tsx": () => import("./../../../src/pages/tracking.tsx" /* webpackChunkName: "component---src-pages-tracking-tsx" */),
  "component---src-pages-user-courses-course-component-tsx": () => import("./../../../src/pages/user/courses/CourseComponent.tsx" /* webpackChunkName: "component---src-pages-user-courses-course-component-tsx" */),
  "component---src-pages-user-courses-index-tsx": () => import("./../../../src/pages/user/courses/index.tsx" /* webpackChunkName: "component---src-pages-user-courses-index-tsx" */),
  "component---src-pages-user-index-tsx": () => import("./../../../src/pages/user/index.tsx" /* webpackChunkName: "component---src-pages-user-index-tsx" */)
}

