import axios from 'axios'
import { getDefaultOptions } from './auth'
import { getUrl } from './getUrl'
import { handleApi } from './handleApiCall'

export const getNotifications = async () => {
	return handleApi(axios.get(getUrl('notification/noticeboard'), getDefaultOptions()))
}

export const markAsRead = async (uid: string) => {
	return handleApi(axios.post(getUrl(`notification/mark_as_read?notification_id=${uid}`), {}, getDefaultOptions()))
}
export const addNotification = async ({
	course,
	description,
	include_teacher,
	title,
	cdn,
}: {
	course: Array<string>
	description: string
	title: string
	cdn: string | null
	include_teacher: boolean
}) => {
	return handleApi(axios.post(getUrl(`notification/add`), { course, description, include_teacher, title, cdn }, getDefaultOptions()))
}
