import { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'

export const handleApi = async <T extends unknown>(promise: Promise<AxiosResponse<T>>) => {
	try {
		return (await promise).data
	} catch (err) {
		console.error(err)
		console.error((err as any)?.response?.data?.message)
		if (
			(err as any)?.response?.data?.message ==
			'QuizRepository validation failed: name: Path `name` is required., questions.0.questionTitle: Path `questionTitle` is required.'
		) {
			toast('Question can not be blank', {
				type: 'error',
			})
			// toast((err as any)?.response?.data?.message, {
			// 	type: 'error',
			// })
		} else if ((err as any)?.response?.data?.message == 'quiz.available_till should be greater than quiz.available_from') {
			toast('Quiz End time should be greater than Start Time.', {
				type: 'error',
			})
		} else if ((err as any)?.response?.data?.message == 'quiz.available_from should be greater than  to quiz.quiz.visible_from ') {
			toast('Quiz Visible Time should be small than Start Time.', {
				type: 'error',
			})
		}
		// FeedbackRepository validation failed: heading: Path `heading` is required., type: Path `type` is required.
		// else if (
		// 	(err as any)?.response?.data?.message ==
		// 	'FeedbackRepository validation failed: heading: Path `heading` is required., type: Path `type` is required.'
		// ) {
		// 	toast('Marks can not be blank', {
		// 		type: 'error',
		// 	})
		// }
		else if (
			(err as any)?.response?.data?.message == 'QuizRepository validation failed: questions.0.questionTitle: Path `questionTitle` is required.'
		) {
			toast('Question can not be blank', {
				type: 'error',
			})
		} else if ((err as any)?.response?.data?.message == 'QuizRepository validation failed: name: Path `name` is required.') {
			toast('Title missing', {
				type: 'error',
			})
		} else {
			{
				console.log((err as any)?.response?.data?.message)
			}
			toast((err as any)?.response?.data?.message, {
				type: 'error',
			})
		}
	}
}
