import React, { Fragment } from 'react'
import { Link } from 'gatsby'

interface MenuLinkProps {
	title: string
	url: string
}

const MenuLink: React.FC<MenuLinkProps> = ({ title, url }) => {
	return (
		<Fragment>
			<Link to={url} activeClassName='menuActive' className='menuLink'>
				<span className='menuName'>{title}</span>
			</Link>
		</Fragment>
	)
}

export default MenuLink
