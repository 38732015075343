import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { UserStateActions } from '../redux/slices/UserState'
import { getNotifications } from 'API/notifications'
function SpeedTest() {
	const dispatch = useDispatch()
	const [notification, setNotifications] = useState<Array<any>>()
	const [speed, setSpeed] = useState<string>('0')
	// const updateNotificationCount = useCallback()
	const updateSpeedValues = useCallback(async () => {
		const imageAddr = '/files/speedtest.jpg'
		const downloadSize = 2707459 // this must match with the image above

		let startTime, endTime
		async function measureConnectionSpeed() {
			startTime = new Date().getTime()
			const cacheBuster = '?nnn=' + startTime

			const download = new Image()
			download.src = imageAddr + cacheBuster
			// this returns when the image is finished downloading
			await download.decode()
			endTime = new Date().getTime()
			const duration = (endTime - startTime) / 1000
			const bitsLoaded = downloadSize * 8
			const speedBps = (bitsLoaded / duration).toFixed(2)
			const speedKbps = (parseFloat(speedBps) / 1024).toFixed(2)
			const speedMbps = (parseFloat(speedKbps) / 1024).toFixed(2)
			return Math.round(Number(speedMbps)).toString()
		}
		try {
			const speed = await measureConnectionSpeed()
			setSpeed(speed)
		} catch (err) {
			console.error('failed to get speed value')
		}
	}, [])

	const GetNotifications = async () => {
		const notifications: Array<any> = await getNotifications()
		if (notifications) {
			setNotifications(notifications)
			dispatch(UserStateActions.updateNotifications({ notifications: notifications }))
		}
	}
	React.useEffect(() => {
		updateSpeedValues()
		GetNotifications()
		const counter = setInterval(() => {
			updateSpeedValues()
			GetNotifications()
		}, 60000)

		return () => clearInterval(counter)
	}, [])

	return <>{speed}</>
}

export default SpeedTest
