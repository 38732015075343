import React, { memo, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import MenuLink from './MenuLink'
import { getUrl } from 'API/getUrl'
import profile from 'images/dashboard/profile.jpg'
import { Icon } from '@iconify/react'
import SpeedTest from 'components/SpeedTest'
import { getUserData } from 'API/user'
import { PromiseType } from 'utility-types'
import { logout } from '../../API/auth'
import { navigate } from 'gatsby'
import { useSelector } from 'react-redux'
import { FaBell } from 'react-icons/fa'
import { Link } from 'gatsby'
import { RootState } from 'redux/store'
interface TopNavProps {
	menuArray: { id: number; title: string; url: string }[]
	toggleMobileNavbar(): void
	isNavExpanded: boolean
}
const getIcon = (type: string) => {
	switch (type) {
		case 'assignment':
			return 'bx bx-task'
		case 'quiz':
			return 'bx bx-question-mark'
		case 'lecture':
			return 'bx bxs-chalkboard'
		case 'resource':
			return 'bx bx-link'
		default:
			return 'bx bxs-bell'
	}
}

const TopNav: React.FC<TopNavProps> = ({ menuArray, toggleMobileNavbar, isNavExpanded, type }) => {
	const [userData, setUserData] = React.useState<PromiseType<ReturnType<typeof getUserData>>>()
	const { notifications } = useSelector((state: RootState) => state.UserReducer)
	const [date_val, setDateVal] = React.useState(Date.now())
	React.useEffect(() => {
		const interval = setInterval(() => {
			setDateVal(Date.now())
		}, 500)
		return () => {
			clearInterval(interval)
		}
	}, [])
	React.useEffect(() => {
		const run = async () => {
			// runAuth()
			const userData = await getUserData()
			if (userData) {
				setUserData(userData)
			}
		}
		run()
		// async function get_data() {
		// getUserData(await ApiLinks.user_data(localStorage.getItem('uid')))
		// }
		// get_data()
	}, [])
	const [_chatNotifications] = useState([
		{
			id: 1,
			name: 'Pushkar Asapure',
			date: 'Monday',
			msg: 'Hello ,How are you?',
			profileImg: profile,
		},
		{
			id: 2,
			name: 'Pushkar Asapure',
			date: 'Monday',
			msg: 'Hello ,How are you?',
			profileImg: profile,
		},
		{
			id: 3,
			name: 'Pushkar Asapure',
			date: 'Monday',
			msg: 'Hello ,How are you?',
			profileImg: profile,
		},
	])
	const [_activityNotifications] = useState([
		{
			id: 1,
			name: 'Pushkar Asapure',
			date: 'Monday',
			msg: 'Posted a New Assignment.',
			type: 'assignment',
			url: '/classes/84ad3ea6-7f1a-48d9-8707-8c106c2c5eb1/classwork/t/48ee71c6-6f5f-4fb8-9fc5-ee0ccff08bed/instructions',
		},
		{
			id: 2,
			name: 'Aniket Chowdhury',
			date: 'Monday',
			msg: 'Scheduled a New Quiz.',
			type: 'quiz',
			url: '/classes/84ad3ea6-7f1a-48d9-8707-8c106c2c5eb1/classwork/t/48ee71c6-6f5f-4fb8-9fc5-ee0ccff08bed/instructions',
		},
		{
			id: 3,
			name: 'Pushkar Asapure',
			date: 'Monday',
			msg: 'Uploaded a New Lecture.',
			type: 'lecture',
			url: '/classes/84ad3ea6-7f1a-48d9-8707-8c106c2c5eb1/classwork/t/48ee71c6-6f5f-4fb8-9fc5-ee0ccff08bed/instructions',
		},
		{
			id: 4,
			name: 'Aaryan Kapur',
			date: 'Monday',
			msg: 'Added a New Resource.',
			type: 'lecture',
			url: '/classes/84ad3ea6-7f1a-48d9-8707-8c106c2c5eb1/classwork/t/48ee71c6-6f5f-4fb8-9fc5-ee0ccff08bed/instructions',
		},
	])
	const notificationIcon = getIcon(type)
	return (
		<nav className='navbarTop navbar navbar-expand-lg navbar-light navbarSticky rounded'>
			<div className='container-fluid'>
				<header className='d-flex' id='header'>
					<div className='header-mb'>
						<i
							className={isNavExpanded ? 'bx bx-dots-vertical-rounded d-lg-none d-block' : 'bx bx-menu d-lg-none d-block'}
							id='header-mb'
							onClick={() => toggleMobileNavbar()}
						></i>
					</div>
				</header>

				{menuArray !== undefined && menuArray.length !== 0 ? (
					<div className='classRoomMenuDesktop d-none d-md-flex'>
						<div className='classMenu'>
							{menuArray.map((menu) => (
								<MenuLink key={menu.id} title={menu.title} url={menu.url}></MenuLink>
							))}
						</div>
					</div>
				) : (
					''
				)}

				<div className='collapse navbar-collapse' id='navbarSupportedContent'>
					<ul className='nav navbar-nav ml-auto align-items-center'>
						{/* <li className='nav-item'> */}
						{/* <span> */}
						{/* <div className='bg-danger rounded-circle activityBox'>
									<i className={`${notificationIcon} bx-md text-white`} style={{ padding: '0.4rem' }}></i>
								</div> */}

						<Link to='/notification' style={{ padding: '0 8px', fontWeight: 'bold' }}>
							<FaBell /> {'(' + notifications.length + ')'}
						</Link>
						{/* </span> */}
						{/* </li> */}
						<li className='nav-item'>
							<span style={{ padding: '0 8px', fontWeight: 'bold' }}>
								<SpeedTest /> Mbps
							</span>
						</li>

						<li className='nav-item'>
							<span>
								{new Date(date_val).toLocaleString(undefined, {
									hour12: true,
									day: 'numeric',
									hour: '2-digit',
									minute: '2-digit',
									month: 'short',
									year: 'numeric',
									second: '2-digit',
								})}
							</span>
						</li>

						<li className='nav-item profileDropdown'>
							<Dropdown>
								<Dropdown.Toggle className='no-shadow no-hover navDropdown' variant='white'>
									<div className='avatar'>
										{userData?.photo_file.photo_file !== '' && (
											<img
												src={`${
													userData?.photo_file.photo_file
														? getUrl(`static/${userData?.photo_file.photo_file}?token=${userData.photo_file.photo_token}`)
														: ''
												}`}
												alt={profile}
												className='rounded-circle profileAvatar'
											/>
										)}
										{userData?.photo_file.photo_file === '' && (
											<img src={profile} alt={profile} className='rounded-circle profileAvatar' />
										)}
									</div>
									{/* <i className="bx bxs-user-circle bx-sm "></i> */}
								</Dropdown.Toggle>

								<Dropdown.Menu className='p-2'>
									<h6 className='text- p-2'>Welcome!</h6>
									<Dropdown.Item className='my-2' href='/profile/view'>
										<Icon className='bx-sm mr-4 text-muted align-top' icon='bx:bxs-user-circle'></Icon>
										{/* <img
											src={`${
												userData?.photo.photo_file
													? getUrl(`static/${userData?.photo.photo_file}?token=${userData.photo.photo_token}`)
													: ''
											}`}
											alt={profile}
											className='rounded-circle profileAvatar'
										/> */}
										<span>My Profile</span>
									</Dropdown.Item>
									<Dropdown.Item
										className='border-top'
										onClick={async (e: any) => {
											e.preventDefault()
											const log = await logout()
											if (log) {
												localStorage.clear()
												navigate('/')
												// window?.location?.reload()
											}
										}}
										href='/'
									>
										<Icon icon='bx:bx-log-out' className='bx-sm mr-4 text-muted align-top' />
										<span>Logout</span>
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</li>
						{/* <li>
							<Icon
								style={{ cursor: 'pointer' }}
								onClick={() => {
									localStorage.clear()
									navigate('/')
								}}
								icon='bx:bx-log-out'
								className='bx-sm mr-4 text-muted align-top'
							/>
						</li> */}
					</ul>
				</div>

				<li className='nav-item hidenav profileDropdown'>
					<Dropdown>
						<Link className='center-block' to='/notification' style={{ padding: '0 8px', fontWeight: 'bold' }}>
							<FaBell /> {'(' + notifications.length + ')'}
						</Link>
						<Dropdown.Toggle className='no-shadow no-hover navDropdown row' variant='white'>
							<div className='avatar'>
								{userData?.photo_file.photo_file !== '' && (
									<img
										src={`${
											userData?.photo_file.photo_file
												? getUrl(`static/${userData?.photo_file.photo_file}?token=${userData.photo_file.photo_token}`)
												: ''
										}`}
										alt={profile}
										className='rounded-circle profileAvatar'
									/>
								)}
								{userData?.photo_file.photo_file === '' && (
									<img src={profile} alt={profile} className='rounded-circle profileAvatar' />
								)}
							</div>
							{/* <i className="bx bxs-user-circle bx-sm "></i> */}
						</Dropdown.Toggle>

						<Dropdown.Menu className='p-2'>
							<h6 className='text- p-2'>Welcome!</h6>
							<Dropdown.Item className='my-2' href='/profile/view'>
								<Icon className='bx-sm mr-4 text-muted align-top' icon='bx:bxs-user-circle'></Icon>
								<span>My Profile</span>
							</Dropdown.Item>
							<Dropdown.Item
								className='border-top'
								onClick={async (e: any) => {
									e.preventDefault()
									const log = await logout()
									if (log) {
										localStorage.clear()
										navigate('/')
										// window?.location?.reload()
									}
								}}
								href='/'
							>
								<Icon icon='bx:bx-log-out' className='bx-sm mr-4 text-muted align-top' />
								{/* <i className='fa fa-sign-out text-muted bx-sm mr-4 align-top'></i> */}
								<span>Logout</span>
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</li>
			</div>
			{menuArray !== undefined && menuArray.length !== 0 ? (
				<div className='classRoomMenuMobile d-flex d-md-none'>
					<div className='classMenu'>
						{menuArray.map((menu) => (
							<MenuLink key={menu.id} title={menu.title} url={menu.url}></MenuLink>
						))}
					</div>
				</div>
			) : (
				''
			)}
		</nav>
	)
}

export default memo(TopNav)
