import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import MenuLink from './MenuLink'
import PageLinks from './PageLinks'
import { Link } from 'gatsby'
import { logout } from '../../API/auth'
import { navigate } from 'gatsby'
import { getUrl } from 'API/getUrl'
import { PromiseType } from 'utility-types'
import { getUserData } from 'API/user'
import profile from 'images/dashboard/profile.jpg'
import { Icon } from '@iconify/react'

interface TopNavProps {
	menuArray: { id: number; title: string; url: string }[]
	toggleMobileNavbar(): void
	isNavExpanded: boolean
}

const TopNav: React.FC<TopNavProps> = ({ menuArray, toggleMobileNavbar, isNavExpanded }) => {
	const [date_val, setDateVal] = React.useState(Date.now())
	const [userData, setUserData] = React.useState<PromiseType<ReturnType<typeof getUserData>>>()
	React.useEffect(() => {
		const run = async () => {
			// runAuth()
			const userData = await getUserData()
			if (userData) {
				setUserData(userData)
			}
		}
		run()
		// async function get_data() {
		// getUserData(await ApiLinks.user_data(localStorage.getItem('uid')))
		// }
		// get_data()
	}, [])
	React.useEffect(() => {
		const interval = setInterval(() => {
			setDateVal(Date.now())
		}, 500)
		return () => {
			clearInterval(interval)
		}
	}, [])
	const [_chatNotifications] = useState([
		{
			id: 1,
			name: 'Pushkar Asapure',
			date: 'Monday',
			msg: 'Hello ,How are you?',
			profileImg: profile,
		},
		{
			id: 2,
			name: 'Pushkar Asapure',
			date: 'Monday',
			msg: 'Hello ,How are you?',
			profileImg: profile,
		},
		{
			id: 3,
			name: 'Pushkar Asapure',
			date: 'Monday',
			msg: 'Hello ,How are you?',
			profileImg: profile,
		},
	])
	const [_activityNotifications] = useState([
		{
			id: 1,
			name: 'Pushkar Asapure',
			date: 'Monday',
			msg: 'Posted a New Assignment.',
			type: 'assignment',
			url: '/classes/84ad3ea6-7f1a-48d9-8707-8c106c2c5eb1/classwork/t/48ee71c6-6f5f-4fb8-9fc5-ee0ccff08bed/instructions',
		},
		{
			id: 2,
			name: 'Aniket Chowdhury',
			date: 'Monday',
			msg: 'Scheduled a New Quiz.',
			type: 'quiz',
			url: '/classes/84ad3ea6-7f1a-48d9-8707-8c106c2c5eb1/classwork/t/48ee71c6-6f5f-4fb8-9fc5-ee0ccff08bed/instructions',
		},
		{
			id: 3,
			name: 'Pushkar Asapure',
			date: 'Monday',
			msg: 'Uploaded a New Lecture.',
			type: 'lecture',
			url: '/classes/84ad3ea6-7f1a-48d9-8707-8c106c2c5eb1/classwork/t/48ee71c6-6f5f-4fb8-9fc5-ee0ccff08bed/instructions',
		},
		{
			id: 4,
			name: 'Aaryan Kapur',
			date: 'Monday',
			msg: 'Added a New Resource.',
			type: 'lecture',
			url: '/classes/84ad3ea6-7f1a-48d9-8707-8c106c2c5eb1/classwork/t/48ee71c6-6f5f-4fb8-9fc5-ee0ccff08bed/instructions',
		},
	])
	return (
		<nav className='navbarTop navbar navbar-expand-lg navbar-light navbarSticky rounded'>
			<div className='container-fluid'>
				<header className='d-flex' id='header'>
					<div className='header-mb'>
						<i
							className={isNavExpanded ? 'bx bx-dots-vertical-rounded d-lg-none d-block' : 'bx bx-menu d-lg-none d-block'}
							id='header-mb'
							onClick={() => toggleMobileNavbar()}
						></i>
					</div>
				</header>

				{menuArray !== undefined && menuArray.length !== 0 ? (
					<div className='classRoomMenuDesktop d-none d-md-flex'>
						<div className='classMenu'>
							{menuArray.map((menu) => (
								<MenuLink key={menu.id} title={menu.title} url={menu.url}></MenuLink>
							))}
						</div>
					</div>
				) : (
					''
				)}

				<div className='collapse navbar-collapse' id='navbarSupportedContent'>
					<ul className='nav navbar-nav ml-auto align-items-center'>
						{/* <li className='nav-item chatNotification'>
							<Dropdown>
								<Dropdown.Toggle className='no-shadow no-hover navDropdown' variant='white'>
									<i className='bx bxs-chat bx-sm'></i>
								</Dropdown.Toggle>

								<Dropdown.Menu className=' dropdown-menu-right'>
									<div className='px-3 py-3'>
										<h6 className='text-sm text-muted m-0'>
											You have <strong className='text-primary'>{chatNotifications.length}</strong> new messages.
										</h6>
									</div>

									{chatNotifications.map((row) => (
										<ChatNotification
											key={row.id}
											id={row.id}
											name={row.name}
											date={row.date}
											msg={row.msg}
											profile={row.profileImg}
										/>
									))}

									<div className='px-3 py-1 text-center'>
										<Link to='/chat' className='btn btn-primary btn-block no-shadow no-hover'>
											View All
										</Link>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</li>
						<li className='nav-item chatNotification'>
							<Dropdown>
								<Dropdown.Toggle className='no-shadow no-hover navDropdown' variant='white'>
									<i className='bx bxs-bell bx-sm'></i>
								</Dropdown.Toggle>

								<Dropdown.Menu className=' dropdown-menu-right'>
									<div className='px-3 py-3 border-bottom'>
										<h6 className='text-sm text-muted m-0'>
											You have <strong className='text-primary'>{activityNotifications.length}</strong> notifications.
										</h6>
									</div>

									{activityNotifications.map((row) => (
										<ActivityNotification
											key={row.id}
											id={row.id}
											name={row.name}
											date={row.date}
											msg={row.msg}
											type={row.type}
											url={row.url}
										/>
									))}

									<div className='px-3 py-1 text-center'>
										<Link to='/s/activities' className='btn btn-primary btn-block no-shadow no-hover'>
											View All
										</Link>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</li> */}
						<li className='nav-item'>
							<span>
								{new Date(date_val).toLocaleString(undefined, {
									hour12: true,
									day: 'numeric',
									hour: '2-digit',
									minute: '2-digit',
									month: 'short',
									year: 'numeric',
									second: '2-digit',
								})}
							</span>
						</li>
						<li className='nav-item'>
							<Dropdown>
								<Dropdown.Toggle className='no-shadow no-hover navDropdown' variant='white'>
									{/* <i className='bx bx-minus-back bx-sm'></i> */}
								</Dropdown.Toggle>

								<Dropdown.Menu className='bg-dark'>
									<PageLinks />
								</Dropdown.Menu>
							</Dropdown>

							{/* <a className="nav-link" href="/">
								<i className="bx bxs-chat bx-sm"></i>
							</a> */}
						</li>

						<li className='nav-item profileDropdown'>
							<Dropdown>
								<Dropdown.Toggle className='no-shadow no-hover navDropdown' variant='white'>
									<div className='avatar'>
										{userData?.photo_file.photo_file !== '' && (
											<img
												src={`${
													userData?.photo_file.photo_file
														? getUrl(`static/${userData?.photo_file.photo_file}?token=${userData.photo_file.photo_token}`)
														: ''
												}`}
												alt={profile}
												className='rounded-circle profileAvatar'
											/>
										)}
										{userData?.photo_file.photo_file === '' && (
											<img src={profile} alt={profile} className='rounded-circle profileAvatar' />
										)}
									</div>
									{/* <i className="bx bxs-user-circle bx-sm "></i> */}
								</Dropdown.Toggle>

								<Dropdown.Menu className='p-2'>
									<h6 className='text- p-2'>Welcome!</h6>
									<Dropdown.Item className='my-2' href='/profile/view'>
										<Icon className='bx-sm mr-4 text-muted align-top' icon='bx:bxs-user-circle'></Icon>
										<span>My Profile</span>
									</Dropdown.Item>
									<Dropdown.Item
										className='border-top'
										onClick={async (e: any) => {
											e.preventDefault()
											const log = await logout()
											if (log) {
												localStorage.clear()
												navigate('/')
												// window?.location?.reload()
											}
										}}
										href='/'
									>
										<Icon icon='bx:bx-log-out' className='bx-sm mr-4 text-muted align-top' />
										{/* <i className='fa fa-sign-out text-muted bx-sm mr-4 align-top'></i> */}
										<span>Logout</span>
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</li>
					</ul>
				</div>

				{/* <li className='nav-item d-block d-lg-none'>
					<Link className='nav-link text-secondary' to='/profile/view'>
						<i className='bx bxs-user-circle bx-md '></i>
					</Link>
				</li> */}
				<li className='nav-item hidenav profileDropdown'>
					<Dropdown>
						<Dropdown.Toggle className='no-shadow no-hover navDropdown' variant='white'>
							<div className='avatar'>
								{userData?.photo_file.photo_file !== '' && (
									<img
										src={`${
											userData?.photo_file.photo_file
												? getUrl(`static/${userData?.photo_file.photo_file}?token=${userData.photo_file.photo_token}`)
												: ''
										}`}
										alt={profile}
										className='rounded-circle profileAvatar'
									/>
								)}
								{userData?.photo_file.photo_file === '' && (
									<img src={profile} alt={profile} className='rounded-circle profileAvatar' />
								)}
							</div>
							{/* <i className="bx bxs-user-circle bx-sm "></i> */}
						</Dropdown.Toggle>

						<Dropdown.Menu className='p-2'>
							<h6 className='text- p-2'>Welcome!</h6>
							<Dropdown.Item className='my-2' href='/profile/view'>
								<Icon className='bx-sm mr-4 text-muted align-top' icon='bx:bxs-user-circle'></Icon>
								<span>My Profile</span>
							</Dropdown.Item>
							<Dropdown.Item
								className='border-top'
								onClick={async (e: any) => {
									e.preventDefault()
									const log = await logout()
									if (log) {
										localStorage.clear()
										navigate('/')
										// window?.location?.reload()
									}
								}}
								href='/'
							>
								<Icon icon='bx:bx-log-out' className='bx-sm mr-4 text-muted align-top' />
								{/* <i className='fa fa-sign-out text-muted bx-sm mr-4 align-top'></i> */}
								<span>Logout</span>
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</li>
			</div>
			{menuArray !== undefined && menuArray.length !== 0 ? (
				<div className='classRoomMenuMobile d-flex d-md-none'>
					<div className='classMenu'>
						{menuArray.map((menu) => (
							<MenuLink key={menu.id} title={menu.title} url={menu.url}></MenuLink>
						))}
					</div>
				</div>
			) : (
				''
			)}
		</nav>
	)
}

export default TopNav
