import React, { useState } from 'react'
import TopNav from 'components/dashboard/TopNav'
import SideNav from 'components/dashboard/SideNav'
import { ToastContainer } from 'react-toastify'
import { useAppSelector } from 'redux/hooks'
import { LoginPage } from 'components/Login'
import { login } from 'API/auth'
import { useAuth } from 'hooks/useAuth'
import 'react-toastify/dist/ReactToastify.css'
import { Layout } from './BaseLayout'
export const UserLayout: React.FC = ({ children }) => {
	const { run } = useAuth()
	const [isNavExpanded, setNavExpanded] = useState(false)
	const [isNavLocked, setNavLocked] = useState(false)

	const loggedIn = useAppSelector((state) => state.ApplicationReducer.logged_in)

	const handleLogin = async (body: { email: string; password: string }) => {
		await login(body)
		run()
	}

	const showNavbar = () => {
		if (!isNavLocked) setNavExpanded(true)
	}
	const hideNavbar = () => {
		if (!isNavLocked) setNavExpanded(false)
	}
	const toggleNavbar = () => {
		setNavExpanded(isNavExpanded)
		setNavLocked(!isNavLocked)
	}
	const toggleMobileNavbar = () => {
		setNavExpanded(!isNavExpanded)
		setNavLocked(!isNavLocked)
	}

	return (
		<>
			<Layout />
			<ToastContainer></ToastContainer>
			<div className='mainContent'>
				{loggedIn && (
					<div className={isNavExpanded ? 'body-pd' : 'body'} id='body-pd'>
						<TopNav menuArray={[]} toggleMobileNavbar={toggleMobileNavbar} isNavExpanded={isNavExpanded}></TopNav>

						<SideNav
							isNavExpanded={isNavExpanded}
							showNavbar={showNavbar}
							hideNavbar={hideNavbar}
							isNavLocked={isNavLocked}
							toggleNavbar={toggleNavbar}
						></SideNav>
						<>{children}</>
					</div>
				)}
			</div>
			{!loggedIn && (
				<div>
					<LoginPage handleLogin={handleLogin}></LoginPage>
				</div>
			)}
		</>
	)
}
