import { createSlice, current, PayloadAction } from '@reduxjs/toolkit'

export type QuestionType =
	| {
			id: number
			questionTitle: string
			questionAnswer: { id: number; value: string }[]
			selected: string
			require: boolean
			type: 1
			optionId: number
			marks: number
			givenMarks?: number
			cdn?: string | null
	  }
	| {
			id: number
			questionTitle: string
			questionAnswer: { id: number; value: string }[]
			selected: string
			require: boolean
			type: 2
			optionId: number
			marks: number
			givenMarks?: number
			cdn?: string | null
	  }
	| {
			id: number
			questionTitle: string
			questionAnswer: { id: number; value: string }[]
			selected: number
			require: boolean
			type: 3
			optionId: number
			marks: number
			givenMarks?: number
			cdn?: string | null
	  }
	| {
			id: number
			questionTitle: string
			questionAnswer: { id: number; value: string }[]
			selected: number[]
			require: boolean
			type: 4
			optionId: number
			marks: number
			givenMarks?: number
			cdn?: string | null
	  }
	| {
			id: number
			questionTitle: string
			questionAnswer: { id: number; value: string }[]
			selected: string | null
			require: boolean
			type: 5
			optionId: number
			marks: number
			givenMarks?: number
			cdn?: string | null
	  }

export type QuestionData = {
	visible_from: number
	available_from: number
	accessible_by: Array<string>
	available_till: number
	added_by: string
	course: string
	durationInSeconds: number
	uid: string
	proctor: boolean
	description: string
	name: string
	releaseMarksDate: number
	marks?: number
	zoom_link?: string
	can_start_exam: boolean
	shuffle_questions?: string
	allowMultiple: false
}

export const baseQuestion = {
	id: 1,
	questionTitle: '',
	questionAnswer: [
		{
			id: 1,
			value: 'Option 1',
		},
	],
	selected: '',
	require: false,
	type: 1,
	optionId: 1,
	marks: 5,
}

export interface QuizState {
	id: number
	questionData: QuestionData
	questions: QuestionType[]
	attemptData: {
		attempting: boolean
		totalTime: number
		timeSpent: number
		timeLeft: number
	}
	save: boolean
}
const initialState: QuizState = {
	id: 0,
	save: false,
	attemptData: {
		attempting: false,
		timeSpent: 0,
		totalTime: 0,
		timeLeft: 0,
	},
	questionData: {
		name: '',
		uid: '',
		description: '',
		accessible_by: [],
		allowMultiple: false,
		proctor: false,
		visible_from: Date.now(),
		available_from: Date.now() + 3600 * 1000,
		available_till: Date.now() + 7200 * 1000,
		releaseMarksDate: Date.now() + 24 * 3600 * 1000,
		added_by: '',
		course: '',
		durationInSeconds: 3600,
		can_start_exam: false,
		shuffle_questions: '',
	},
	questions: [baseQuestion as any],
}

export const slice = createSlice({
	name: 'QuizState',
	initialState,
	reducers: {
		setQuizData: (state, action: PayloadAction<typeof initialState.attemptData>) => {
			state.attemptData = action.payload
			return state
		},
		reset: (state) => {
			state = initialState
			return state
		},
		addQuestion: (state, action: PayloadAction<{ last_id: number }>) => {
			const questions = state.questions
			const index = questions.findIndex((elem) => elem.id === action.payload.last_id) + 1
			const new_id = Math.max(...questions.map((elem) => elem.id)) + 1
			questions.splice(index, 0, { ...baseQuestion, id: new_id, type: 1 })
			state.questions = questions
			console.log(questions.length)
			return state
		},
		removeQuestion: (state, action: PayloadAction<{ id: number }>) => {
			const questions = state.questions
			const index = questions.findIndex((elem) => elem.id === action.payload.id)
			if (questions.length === 1) return
			questions.splice(index, 1)

			state.questions = questions
			console.log(questions.length)
			return state
		},
		updateId: (state) => {
			if (state.id === 0) {
				const id = Math.max(...state.questions.map((elem) => elem.id)) + 1
				if (id === -Infinity) {
					state.id = 1
				} else {
					state.id = id
				}
			} else {
				state.id = state.id + 1
			}
		},
		setQuestionData: (state, action: PayloadAction<Partial<QuestionData>>) => {
			console.log(action.payload)
			const questionData = { ...state.questionData, ...action.payload }
			state.questionData = questionData
			return state
		},

		setState: (state, action: PayloadAction<Partial<QuizState>>) => {
			console.log({ payload: action.payload })
			state = {
				...state,
				...action.payload,
			}
			return state
		},
		updateGivenMarks: (state, action: PayloadAction<{ id: number; givenMarks: number }>) => {
			const { id, givenMarks } = action.payload

			const questions = state.questions
			const index = questions.findIndex((elem) => elem.id === id)
			const question = questions[index]
			question.givenMarks = givenMarks

			questions[index] = question
			state.questions = questions
			return state
		},
		updateQuestion: (state, action: PayloadAction<{ id: number; question: Omit<Partial<QuestionType>, 'type' | 'selected'> }>) => {
			const questions = state.questions
			const index = questions.findIndex((elem) => elem.id === action.payload.id)
			questions[index] = { ...questions[index], ...action.payload.question }
			state.questions = questions
			return state
		},
		updateQuestionType: (state, action: PayloadAction<{ id: number; type: 1 | 2 | 3 | 4 | 5 }>) => {
			const type = action.payload.type
			const questions = state.questions
			const index = questions.findIndex((elem) => elem.id === action.payload.id)
			questions[index].type = action.payload.type
			if (type === 1) {
				questions[index].selected = 'null'
			}
			if (type === 2) {
				questions[index].selected = 'null'
			}
			if (type === 3) {
				questions[index].selected = 0
			}
			if (type === 4) {
				questions[index].selected = []
			}
			if (type === 5) {
				questions[index].selected = ''
			}
			state.questions = questions
			// console.log(action.payload.questionTitle)
			return state
		},
		updateTextAnswer: (state, action: PayloadAction<{ id: number; selected: string }>) => {
			const { id, selected } = action.payload

			const questions = state.questions
			const index = questions.findIndex((elem) => elem.id === id)
			const question = questions[index]
			if (question.type === 1 || question.type === 2) {
				question.selected = selected
				questions[index] = question
				state.questions = questions
				return state
			} else {
				return state
			}
		},
		updateAnswerFileUpload: (state, action: PayloadAction<{ id: number; selected: string | null }>) => {
			const { id, selected } = action.payload
			const questions = state.questions
			const index = questions.findIndex((elem) => elem.id === id)
			const question = questions[index]
			if (question.type === 5) {
				question.selected = selected
				questions[index] = question
				state.questions = questions
				return state
			} else {
				return state
			}
		},
		updateSelectedSingleCorrect: (state, action: PayloadAction<{ id: number; selected: number }>) => {
			const { id, selected } = action.payload

			const questions = state.questions
			const index = questions.findIndex((elem) => elem.id === id)
			const question = questions[index]
			if (question.type === 3) {
				question.selected = selected
				questions[index] = question
				state.questions = questions
				return state
			} else {
				return state
			}
		},
		updateToBase: (state, action: PayloadAction<{ id: number }>) => {
			const { id } = action.payload
			const questions = state.questions
			const index = questions.findIndex((elem) => elem.id === id)
			const question = questions[index]
			question.selected = ''
			state.questions = questions
			return state
		},
		updateMarks: (state, action: PayloadAction<{ id: number; marks: number }>) => {
			const { id, marks } = action.payload

			const questions = state.questions
			const index = questions.findIndex((elem) => elem.id === id)
			const question = questions[index]
			question.marks = marks

			questions[index] = question
			state.questions = questions
			return state
		},

		updateSelectedMultipleCorrect: (state, action: PayloadAction<{ id: number; selected: number }>) => {
			const { id, selected } = action.payload

			const questions = state.questions
			const index = questions.findIndex((elem) => elem.id === id)
			const question = questions[index]
			if (question.type === 4) {
				if (question.selected === null) {
					question.selected = []
				}
				const idx = question.selected.indexOf(selected)
				if (idx === -1) {
					question.selected.push(selected)
				} else {
					question.selected.splice(idx, 1)
				}
				questions[index] = question
				state.questions = questions
				return state
			} else {
				return state
			}
		},
		toggleSave: (state) => {
			state.save = !state.save
			return state
		},
	},
})

export const QuizStateActions = slice.actions

export default slice.reducer

export const _base = [
	{
		id: 1,
		questionTitle: 'One',
		questionAnswer: [
			{
				id: 1,
				value: 'Option 1',
			},
		],
		selected: 'null',
		require: false,
		type: 1,
		optionId: 1,
		marks: 5,
	},
	{
		id: 2,
		questionTitle: 'Two',
		questionAnswer: [
			{
				id: 1,
				value: 'Option 1',
			},
		],
		selected: 'null',
		require: false,
		type: 2,
		optionId: 1.2,
		marks: 10,
	},
	{
		id: 3,
		questionTitle: 'Question One',
		questionAnswer: [
			{
				id: 1,
				value: 'Option 1',
			},
			{
				id: 2,
				value: 'Option 2',
			},
			{
				id: 3,
				value: 'Option 3',
			},
		],
		selected: 1,
		require: false,
		type: 3,
		optionId: 3,
		marks: 1,
	},
	{
		id: 5,
		questionTitle: 'Question Another',
		questionAnswer: [
			{
				id: 1,
				value: 'Option 1',
			},
		],
		selected: 'null',
		require: false,
		type: 5,
		optionId: 3,
		marks: 5,
	},
]
