import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface UserState {
	primaryAccount: string | null
	notifications: Array<any>
}

const initialState: UserState = {
	primaryAccount: null,
	notifications: [],
}

export const slice = createSlice({
	name: 'UserState',
	initialState,
	reducers: {
		setPrimaryAccount: (state, action: PayloadAction<string>) => {
			state.primaryAccount = action.payload
		},
		updateNotifications: (state, { payload }) => {
			state.notifications = payload?.notifications
		},
	},
})

export const UserStateActions = slice.actions
export default slice.reducer
