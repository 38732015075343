import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface AssignmentState {
	assignment_id?: string
	user_id?: string
	first_name?: string
	last_name?: string
}

const initialState: AssignmentState = {}

export const slice = createSlice({
	name: 'AssignmentState',
	initialState,
	reducers: {
		setAssignmentId: (state, action: PayloadAction<string>) => {
			state.assignment_id = action.payload
		},
		setUserId: (state, action: PayloadAction<string>) => {
			state.user_id = action.payload
		},
		setData: (state, action: PayloadAction<{ first_name: string; last_name: string }>) => {
			state.first_name = action.payload.first_name
			state.last_name = action.payload.last_name
			return state
		},
	},
})

export const AssignmentStateActions = slice.actions
export default slice.reducer
