import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface CourseDisplayState {
	type: 'Student' | 'Teacher'
	viewAs: 'Student' | 'Teacher'
	type_of_course: 'Traditional' | 'Online'
	course_id: string
}

const initialState: CourseDisplayState = {
	type: 'Student',
	viewAs: 'Student',
	course_id: '',
	type_of_course: 'Traditional',
}

export const slice = createSlice({
	name: 'CourseDisplayState',
	initialState,
	reducers: {
		setTeacher: (state) => {
			state.type = 'Teacher'
		},
		setStudent: (state) => {
			state.type = 'Student'
		},
		setViewAs: (state, action: PayloadAction<'Student' | 'Teacher'>) => {
			state.viewAs = action.payload
		},
		setCourseType: (state, action: PayloadAction<'Traditional' | 'Online'>) => {
			state.type_of_course = action.payload
		},
		toggle: (state, action?: PayloadAction<'Student' | 'Teacher'>) => {
			if (action?.payload) {
				state.viewAs = action.payload
				return
			}
			if (state.viewAs === 'Student') {
				state.viewAs = 'Teacher'
			} else {
				state.viewAs = 'Student'
			}
		},
		setState: (state, action: PayloadAction<Partial<CourseDisplayState>>) => {
			state = {
				...state,
				...action.payload,
			}
			return state
		},
	},
})

export const CourseDisplayStateActions = slice.actions
export default slice.reducer
