import React, { Fragment } from 'react'
import { Provider } from 'react-redux'
import { AdminLayout } from './src/components/layouts/AdminLayout'
import { UserLayout } from './src/components/layouts/UserLayout'
import { ClassLayout } from './src/components/layouts/ClassLayout'
import { Layout } from './src/components/layouts/BaseLayout'

import store from './src/redux/store'
import './src/styles/main.scss'
import { Location } from '@reach/router'
import 'react-toastify/dist/ReactToastify.css'

export const wrapRootElement = ({ element }) => {
	return (
		<>
			<Provider store={store}>
				<Location>
					{(location) => {
						console.log(location.location.pathname)
						if (['/help', '/forgot_password', '/reset_password'].reduce((a, b) => a || location.location.pathname.startsWith(b), false)) {
							return <Layout> {element}</Layout>
						}
						if (location.location.pathname.startsWith('/admin'))
							return (
								<AdminLayout>
									<Fragment> {element}</Fragment>
								</AdminLayout>
							)
						else if (location.location.pathname.startsWith('/course/'))
							return (
								<ClassLayout>
									<Fragment>{element}</Fragment>
								</ClassLayout>
							)
						else if (location.location.pathname.startsWith('/quiz/')) return <>{element}</>
						return <UserLayout>{element}</UserLayout>
					}}
				</Location>
			</Provider>
		</>
	)
}
