import axios from 'axios'
import { toast } from 'react-toastify'
import { ActivityType } from 'src/pages/course/[id]/activities'
import { RepoMap } from 'components/RepoMap'
import { UserType } from 'types/UserAdditionMetaData'
import { Assign } from 'utility-types'
import { getDefaultOptions } from './auth'
import { getUrl } from './getUrl'
import { handleApi } from './handleApiCall'
import { CourseType } from 'types/CourseAdditionMetaData'

export const addToGroup = async (body: { uids: string[]; group_name?: string }) => {
	if (body.group_name && body.group_name !== '') return await handleApi(axios.post(getUrl('user/batch/add/group'), body, getDefaultOptions()))
	else {
		toast('Select a group', { type: 'error' })
		throw new Error('Select a group')
	}
}

export const getUserData = async () => {
	return handleApi<UserType>(axios.get(getUrl(`user/details`), getDefaultOptions()))
}

export const getUserDataByUid = async (uid: string) => {
	return handleApi<UserType & { courses: { course: CourseType }[] }>(axios.get(getUrl(`user/details/${uid}`), getDefaultOptions()))
}

export const updateMyPassword = async (body: { password: string }) => {
	return handleApi(axios.post(getUrl(`user/update_my_password`), body, getDefaultOptions()))
}

export const getUserByGroup = async (name: string) => {
	return handleApi<{ user: UserType }[]>(axios.get(getUrl(`user/byGroup/${name}`), getDefaultOptions()))
}

export const addUser = async (body: { first_name: string; last_name: string; username: string; email: string; password: string }) => {
	return handleApi<UserType>(axios.post(getUrl(`user/add_user`), body, getDefaultOptions()))
}

export const getUserSearch = async (email: string) => {
	return handleApi<UserType[]>(axios.get(getUrl(`user/search/${email}`), getDefaultOptions()))
}

export const sendReset = async (email: string, url: string) => {
	return handleApi<any>(axios.post(getUrl(`user/send_reset_password`), { email, url }, getDefaultOptions()))
}

export const resetPassword = async (password: string, token: string) => {
	return handleApi<any>(axios.post(getUrl(`user/reset_password`), { token, password }, getDefaultOptions()))
}
// ;`link: req.protocol + '://' + req.hostname,`
export type ActivityAccessType = {
	first_opened_at: Date
	last_opened_at: Date
	submitted_at?: Date
	createdAt?: Date
	updatedAt?: Date
	asset: {
		__t: RepoMap
		name: string
		uid: string
	}
}

export const getActivityLog = async (data: { asTeacher: boolean; course_id: string; student_id: string }) => {
	return handleApi<Array<{ user: { first_name: string; last_name: string } } & ActivityAccessType>>(
		axios.get(
			getUrl(`asset/activity_log?asTeacher=${data.asTeacher}&course_id=${data.course_id}&student_id=${data.student_id}`),
			getDefaultOptions()
		)
	)
}

export const getActivityLogCourse = async (data: { course_id: string }) => {
	return handleApi<Array<{ user: { first_name: string; last_name: string; uid: string; username?: string } } & ActivityAccessType>>(
		axios.get(getUrl(`asset/activity_log_course?asTeacher=${true}&course_id=${data.course_id}`), getDefaultOptions())
	)
}

export const getFiles = async () => {
	return handleApi<{ name: string; createdAt: string; uid: string; original_name: string; file_extension: string; parent_folder: null | string }[]>(
		axios.get(getUrl(`cdn/files`), getDefaultOptions())
	)
}

export const getTracking = async (filter = true) => {
	return handleApi<Assign<ActivityType, { course: { name: string; uid: string } }>[]>(
		axios.get(getUrl(`user/tracking?filter=${filter}`), getDefaultOptions())
	)
}
export const getAllUsers = () => {
	return handleApi<UserType[]>(axios.get(getUrl(`user/all`), getDefaultOptions()))
}

export const getOnlineUsers = async () => {
	return await handleApi<UserType[]>(axios.get(getUrl('user/online_users'), getDefaultOptions()))
}
