import { combineReducers, configureStore } from '@reduxjs/toolkit'
import UserReducer from './slices/UserState'
import ApplicationReducer from './slices/ApplicationState'
import CourseDisplayReducer from './slices/CourseDisplayState'
import QuizReducer from './slices/QuizState'
import AssignmentReducer from './slices/AssignmentState'
import QuizSubmissionReducer from './slices/QuizSubmissionState'

const store = configureStore({
	reducer: combineReducers({
		UserReducer,
		ApplicationReducer,
		CourseDisplayReducer,
		QuizReducer,
		AssignmentReducer,
		QuizSubmissionReducer,
	}),
})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
