import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ApplicationState {
	logged_in: boolean
	selectedGroup?: string
	uids: string[]
	search: string
	update: number
	once: boolean
}

const initialState: ApplicationState = {
	logged_in: false,
	uids: [],
	search: '',
	update: 0,
	once: true,
}

export const slice = createSlice({
	name: 'ApplicationState',
	initialState,
	reducers: {
		setLoggedIn: (state, action: PayloadAction<boolean>) => {
			state.logged_in = action.payload
		},
		setState: (state, action: PayloadAction<Partial<ApplicationState>>) => {
			state = {
				...state,
				...action.payload,
			}
			return state
		},
		setOnce: (state, action: PayloadAction<boolean>) => {
			state.once = action.payload
			return
		},
		setUpdate: (state) => {
			state.update = state.update + 1
		},
	},
})

export const ApplicationStateActions = slice.actions
export const setUpdate = slice.actions.setUpdate
export default slice.reducer
