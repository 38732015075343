import { Icon } from '@iconify/react'
import { navigate } from 'gatsby'
import * as React from 'react'

import { Navbar, Nav } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import logo from '../images/logo.png'
import forgetpassword from '../images/forgetpassword.png'
import './login.scss'
const LoginImg1 = '/login/boy-studying.svg'
const LoginImg2 = '/login/girl-studying.svg'

export const LoginPage: React.FC<{ handleLogin: (body: { email: string; password: string }) => any }> = ({ handleLogin }) => {
	const [mode, setMode] = React.useState('login')

	const [down, setDown] = React.useState(false)

	const [login, setLogin] = React.useState({
		email: '',
		password: '',
	})

	function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		setLogin({
			...login,
			[e.target.name]: e.target.value,
		})
	}

	const toggleMode = () => (mode === 'login' ? setMode('register') : setMode('login'))

	return (
		<React.Fragment>
			<Helmet>
				<script src='https://cdn.jsdelivr.net/npm/boxicons@2.0.8/dist/boxicons.min.js'></script>
			</Helmet>
			<div className={mode === 'login' ? 'loginNav' : 'registerNav'}>
				<Navbar className={'navbarLogin px-lg-5'} expand='lg'>
					<Navbar.Brand className='navbarBrand' href='/'>
						{InstanceName()}
					</Navbar.Brand>
				</Navbar>
			</div>
			<div className={`container loginContainer ${mode === 'login' ? 'sign-in-mode' : 'sign-up-mode'}`}>
				<div className='forms-container'>
					<div className='signin-signup'>
						<form
							onSubmit={(e) => {
								e.preventDefault()
								handleLogin(login)
							}}
							className='sign-in-form'
						>
							<h2 className='title'>Sign in</h2>
							<div className='input-field'>
								<Icon icon={'bx:bx-user'}></Icon>

								<input
									onChange={(e) => {
										handleChange(e)
									}}
									name='email'
									type='text'
									placeholder='Username'
									required
								/>
							</div>
							<div className='input-field'>
								<Icon icon='bx:bx-lock-alt'></Icon>
								<input
									onChange={(e) => {
										handleChange(e)
									}}
									name='password'
									type={down ? 'text' : 'password'}
									placeholder='Password'
									required
								/>
								<Icon
									onMouseDown={() => setDown(true)}
									onMouseUp={() => setDown(false)}
									className='cursor'
									icon={down ? `bx:bx-hide` : `bx:bx-show`}
								></Icon>
							</div>
							<input type='submit' value='Login' className='btn btn-lg px-5 btn-login rounded mt-2' />
							<Nav.Link className='navLink' href='/forgot_password'>
								Forgot Password
							</Nav.Link>
							{/* <Nav.Link className='navLink' href='/help'>
								Help?
							</Nav.Link> */}
						</form>
						<form action='#' className='sign-up-form'>
							<h2 className='title'>Get Help</h2>
							<img src={forgetpassword} className='img-fluid img-res' alt='Responsive image' />

							<p>Please contact Admin for any assistance.</p>
							<a href='https://forms.gle/GCQ7U8dHhiu2V8NV6' target='blank'>
								Need Help ?
								{/* <button
									onClick={() => navigate('/help')}
									type='submit'
									className='btn btn-lg px-5 rounded mt-2'
									value='Need Help?'
								>
									{'Need Help?'}
								</button> */}
							</a>
						</form>
					</div>
				</div>

				<div className='panels-container'>
					<div className='panel left-panel'>
						<div className='content mt-md-4'>
							<h3>Get help?</h3>
							<p>Please contact Admin for any assistance.</p>
							<button className='btn btn-secondary shadow rounded px-4' id='sign-up-btn' onClick={toggleMode}>
								Help
							</button>
						</div>
						<img src={LoginImg1} className='image loginImage' alt='' />
					</div>
					<div className='panel right-panel'>
						<div className='content mt-5'>
							<h3>One of us ?</h3>
							<p>Your credentials are shared with your administrator.</p>
							<button className='btn btn-secondary shadow rounded px-4' id='sign-in-btn' onClick={toggleMode}>
								Sign in
							</button>
						</div>
						<img src={forgetpassword} className='image registerImage' alt='Responsive image' />
						{/* <img src={LoginImg2} className='image registerImage' alt='' /> */}
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

export function InstanceName() {
	// const url = typeof window !== 'undefined' ? window.location.href : ''
	// if (url === 'https://dmims-odlonline-adcc.in/') {
	// 	return (
	// 		<>
	// 			<img width='15%' className='img-fluid logo-img' src='./logo.png' alt='' />
	// 		</>
	// 	)
	// }

	// if (url === 'https://ycce.adcclms.com/') {
	// 	return (
	// 		<>
	// 			<img width='15%' className='img-fluid logo-img' src='./logo.png' alt='' />
	// 		</>
	// 	)
	// }

	return (
		<>
			{/* <div>hi</div> */}
			<img width='15%' className='img-fluid logo-img' src={logo} alt='' />
			{/* <span>MEGHE DIGICAMP - {process.env.GATSBY_HEADER || ''}</span> */}
		</>
	)
}
