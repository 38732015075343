import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface QuizSubmissionState {
	selected_quiz_id?: string

	user_id?: string
	first_name?: string
	last_name?: string
}

const initialState: QuizSubmissionState = {}

export const slice = createSlice({
	name: 'QuizSubmissionState',
	initialState,
	reducers: {
		setSelectedQuizId: (state, action: PayloadAction<string>) => {
			state.selected_quiz_id = action.payload
		},
		setUserId: (state, action: PayloadAction<string>) => {
			state.user_id = action.payload
		},
		setData: (state, action: PayloadAction<{ first_name: string; last_name: string }>) => {
			state.first_name = action.payload.first_name
			state.last_name = action.payload.last_name
			return state
		},
	},
})

export const QuizSubmissionStateActions = slice.actions
export default slice.reducer
