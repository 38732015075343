import axios, { AxiosRequestConfig } from 'axios'
import { toast } from 'react-toastify'
import { getUrl } from './getUrl'
import { handleApi } from './handleApiCall'

export const login = async (body: { email: string; password: string }) => {
	try {
		const { token, uid } = (await axios.post<{ token: string; uid: string }>(getUrl('user/login_email_or_username'), body)).data
		localStorage.setItem('token', token)
		localStorage.setItem('uid', uid)
		return true
	} catch (err) {
		console.error((err as any)?.response?.data?.message)
		if ((err as any)?.response?.data?.message) {
			toast('Check Username/password.', {
				type: 'error',
			})
		}
	}
}

export const getDefaultOptions: () => AxiosRequestConfig = () => {
	return {
		headers: {
			Authorization: localStorage.getItem('token'),
		},
	}
}

export const verify = async ({ admin }: { admin: boolean } = { admin: false }) => {
	if (admin) {
		return await axios.get<{ loggedInOnce: boolean }>(getUrl(`user/verify/admin?&uid=${localStorage.getItem('uid')}`), getDefaultOptions())
	}
	return await axios.get<{ loggedInOnce: boolean }>(getUrl(`user/verify?&uid=${localStorage.getItem('uid')}`), getDefaultOptions())
}

export const logout = async () => {
	const uid = localStorage.getItem('uid')
	return await handleApi(axios.post(getUrl('user/logout'), { uid }, getDefaultOptions()))
}
