import { verify } from 'API/auth'
import { useAppDispatch } from 'redux/hooks'
import { ApplicationStateActions } from 'redux/slices/ApplicationState'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'

export const useAuth = ({ admin, check }: { admin: boolean; check?: boolean } = { admin: false, check: false }) => {
	const dispatch = useAppDispatch()

	const { pathname } = useLocation()
	const run = async () => {
		if (check) {
			check = false
			return
		}
		try {
			const resp = await verify({ admin })
			if (resp.status === 200) {
				if (resp.data.loggedInOnce === false && !admin) {
					dispatch(ApplicationStateActions.setOnce(false))
					if (pathname !== '/profile/view') navigate('/profile/view')
				} else {
					dispatch(ApplicationStateActions.setOnce(true))
				}
				dispatch(ApplicationStateActions.setLoggedIn(true))
			} else {
				dispatch(ApplicationStateActions.setLoggedIn(false))
			}
		} catch (err) {
			dispatch(ApplicationStateActions.setLoggedIn(false))
			if (admin && pathname !== '/admin') {
				navigate('/admin')
			}
			if (!admin && pathname !== '/') {
				navigate('/')
			}
		}
	}
	run()
	return { run }
}
